<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      xs12
      multi-line
      :timeout="timeout"
      top
      color="orange"
    >
      {{ alertmessage }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <sequential-entrance from-top>
      <v-row>
        <v-col>
          <base-material-card
            color="primary"
            icon="mdi-message-alert"
            inline
          >
            <template v-slot:after-heading>
              <div class="text-h3 font-weight-light">
                Notifications
              </div>
            </template>
            <v-data-table
              :headers="headers"
              :items="getalerts"
              sort-by="calories"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-toolbar-title>Notifications</v-toolbar-title>

                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-spacer />
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="warning"
                        dark
                        class="mb-2"
                        to="/alerts"
                      >
                        Return
                      </v-btn><v-btn
                        color="primary"
                        dark
                        class="mb-2 mr-4"
                        v-on="on"
                      >
                        New Notification
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="editedItem.label"
                                label="Label"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-select
                                v-model="editedItem.event_type"
                                :items="altypes"
                                label="Event Type"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-select
                                v-model="editedItem.method"
                                :items="methods"
                                label="Notification Method"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="editedItem.destination"
                                label="Email user@name.com/Mobile (15551212)"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          :disabled="!formIsValid"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon

                  color="primary"
                  size="20"
                  @click="editItem(item)"
                >
                  mdi-square-edit-outline
                </v-icon>
                <v-icon
                  color="black"
                  size="20"
                  class="ma-4"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                Sorry, Alerts to display for Smart Safe ID {{ id }}
              </template>
            </v-data-table>
          </base-material-card>

          <div class="py-3" />
        </v-col>
      </v-row>
    </sequential-entrance>
  </v-container>
</template>

<script>
  import moment from 'moment'
  const axios = require('axios')
  var numeral = require('numeral')

  export default {
    name: 'Alertsbysite',
    data: () => ({
      methods: ['SMS', 'EMAIL'],
      altypes: ['Policy Issued',
                'Policy Lapsing',
                'Premium due in 30 days'
      ],
      dialog: false,
      snackbar: false,
      sitedetails: '',
      timeout: 5000,
      alertmessage: '',
      headers: [
        { text: 'Alert Label', align: 'center', value: 'label' },
        {
          text: 'Event Type',
          align: 'left',
          sortable: false,
          value: 'event_type',
        },
        { text: 'Alert Method', align: 'center', value: 'method' },
        { text: 'address/number', align: 'center', value: 'destination' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      alerts: [],
      editedIndex: -1,
      editedItem: {
        label: '',
        event_type: '',
        method: '',
        destination: ''
      },
      defaultItem: {
        label: '',
        event_type: '',
        method: '',
        destination: ''
      },
    }),

    computed: {
      formIsValid () {
        return this.editedItem.label !== '' &&
          this.editedItem.event_type !== '' &&
          this.editedItem.method !== '' &&
          this.editedItem.destination !== ''
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Notification' : 'Edit Notification'
      },
      getalerts () {
        if (this.$store.getters.alerts) {
          this.alerts = this.$store.getters.alerts
        }
        return this.alerts
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.id = this.$route.params.id
      this.alerts = []
      // this.alerts = this.$store.getters.alerts
      this.$store.dispatch('loadAlerts')
      if (this.$store.getters.alerts) {
        console.log('some alerts to load', this.$store.getters.alerts)
        this.alerts = this.$store.getters.alerts
      }
      console.log(this.alerts)
    },

    methods: {
      editItem (item) {
        this.editedIndex = this.alerts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        const index = this.alerts.indexOf(item)
        if (confirm('Are you sure you want to delete this item?') && this.alerts.splice(index, 1)) {
          this.$store.dispatch('removealert', item)
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        console.log(this.editedItem)
        let notallowedchars = ''
        notallowedchars = this.editedItem.destination.match(/\\t|[ #\$\-+]/gi)
        if (notallowedchars) {
          this.alertmessage = 'Spaces # . $ - are not allowed in an email or mobile number'
          this.snackbar = true
          this.close()
          return
        }
        const alert = {
          event_type: this.editedItem.event_type,
          label: this.editedItem.label.trim(),
          method: this.editedItem.method,
          destination: this.editedItem.destination.trim(),
          key: this.editedItem.key
        }
        if (this.editedIndex > -1) {
          Object.assign(this.alerts[this.editedIndex], this.editedItem)
          this.$store.dispatch('updateAlert', alert)
        } else {
          console.log(this.alerts)
          this.alerts.push(this.editedItem)
          this.$store.dispatch('createalert', alert)
          this.$store.dispatch('loadAlerts')
          this.alerts = this.$store.getters.alerts
        }
        this.close()
      },
    },
  }
</script>
